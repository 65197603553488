:root {
  --black: #171619;
  --black-transparent: #171619d6;
  --blackgreen: rgb(43, 45, 45);
  --white: #eee;
  --light: #ccc;
  --dark: #3a4e48;
  --primary: #ff6f59;
  --primary-light: #fd8e7d;
  --primary-lighter: #fca699;
  --secondary: #43aa8b;
  --secondary-light: #60eec3;
  --secondary-lighter: #91f0d3;
  --red: #e66161;
  --monospace: Inconsolata, "Courier New", Courier, monospace;
  --sans-serif: -apple-system, BlinkMacSystemFont, "Segoe WPC", "Segoe UI", Ubuntu, "Droid Sans",
    sans-serif;
  --monacoblack: #1e1e1e;
}
html,
body,
#root {
  margin: 0;
  height: 100%;
  background: var(--black);
  color: var(--white);
  font-family: var(--sans-serif);
}
.full-height {
  height: 100%;
}
.monospace {
  font-family: var(--monospace);
}

#background-image {
  background-color: #f00;
  background-image: url("https://res.cloudinary.com/droopytersen/image/upload/c_scale,q_auto:good,w_2156/v1556159387/codesnippets/keyboard.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 0;
  filter: blur(6px) grayscale(80%);
}

a {
  color: var(--light);
  text-decoration: none;
}
a:hover {
  color: var(--white);
}
